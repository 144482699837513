import React from 'react';
import { FaEnvelope, FaFacebookF, FaInstagram, FaLinkedin, FaPhoneAlt } from 'react-icons/fa';
import { BiMap } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { businessLogic } from '../../lib/constants';
import { useTranslation } from "react-i18next";


const Footer = () => {
   const { t } = useTranslation();

   return (
      <>
         <footer>
            <div className="footer__area grey-bg pt-100">
               <div className="footer__top pb-45">
                  <div className="container">
                     <div className="row">
                        <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6">
                           <div className="footer__widget mb-45">
                              <div className="footer__widget-title">
                                 <h4>{t("home_footer_title_company")}</h4>
                              </div>
                              <div className="footer__widget-content">
                                 <div className="footer__links">
                                    <ul>
                                       <li><a href="/about">{t("home_footer_company_about_us")}</a></li>
                                       <li><a href="/home#services">{t("home_footer_company_services")}</a></li>
                                       <li><a href={businessLogic.consulting_landing} target='blank'>{t("home_footer_company_consulting")}</a></li>
                                       <li><a href="/blog">{t("home_footer_company_blog")}</a></li>
                                       <li><a href="/contact#contact">{t("home_footer_company_contactus")}</a></li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-xl-2 offset-xl-1 col-lg-2 offset-lg-1 col-md-4 col-sm-6">
                           <div className="footer__widget mb-45 wow fadeInUp" data-wow-delay=".4s">
                              <div className="footer__widget-title">
                                 <h4>{t("home_footer_title_resources")}</h4>
                              </div>
                              <div className="footer__widget-content">
                                 <div className="footer__links">
                                    <ul>
                                       {/* <li><a href="#">Customers</a></li> */}
                                       <li><a href="/home#logo_customers">{t("home_footer_resources_customers")}</a></li>
                                       <li><Link to="/pricing">{t("home_footer_resources_pricing")}</Link></li>
                                       <li><a href="/blog">{t("home_footer_resources_news")}</a></li>
                                       <li><a href="/contact#contact">{t("home_footer_resources_support")}</a></li>




                                       <li><a href="/newsletter-policies">{t("newsletter_policies_title_footer")}</a></li>
                                       <li><a href="/ebook-policies">{t("ebook_download_policies_title_footer")}</a></li>
                                       <li><a href="/contact-policies">{t("contact_policies_title_footer")}</a></li>
                                       <li><a href="/comments-policies">{t("comment_policies_title_footer")}</a></li>
                                       <li><a href="/cookies-policies">{t("cookies_policies_title_footer")}</a></li>




                                       {/* <li><a href="#">Learning Center</a></li> */}
                                       {/* <li><a href="#">Help desk</a></li> */}
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-xl-2 offset-xl-1 col-lg-2 offset-lg-1 col-md-4 col-sm-6">
                           <div className="footer__widget mb-45">
                              <div className="footer__widget-title">
                                 <h4>{t("home_footer_title_usecases")}</h4>
                              </div>
                              <div className="footer__widget-content">
                                 <div className="footer__links">
                                    <ul>
                                       <li><a href={businessLogic.uiux_landing} target='blank'>{t("home_footer_usecases_uiuxdesign")}</a></li>
                                       <li><a href={businessLogic.mobile_landing} target='blank'>{t("home_footer_usecases_mobileapp")}</a></li>
                                       {/* <li><a href={businessLogic.backend_landing} target='blank'>Backend Optimization</a></li> */}
                                       {/* <li><a href="#">SEO for Business</a></li> */}
                                       <li><a href={businessLogic.backend_landing} target='blank'>{t("home_footer_usecases_apiintegration")}</a></li>
                                       <li><a href={businessLogic.database_landing} target='blank'>{t("home_footer_usecases_database")}</a></li>
                                       <li><a href={businessLogic.saas_landing} target='blank'>{t("home_footer_usecases_software")}</a></li>
                                       {/* <li><a href={businessLogic.data_landing} target='blank'>{t("home_footer_usecases_analytics")}</a></li> */}
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>



                        <div className="col-xl-3 offset-xl-1 col-lg-3 offset-lg-1 col-md-4 col-sm-6">
                           <div className="footer__widget mb-45">
                              <div className="footer__widget-title">
                                 <h4>{t("home_footer_title_information")}</h4>
                              </div>


                              {/* <div className="footer__widget-title mb-30">
                                 <div className="logo">
                                    <Link to="/home">
                                       <img src={`assets/img/logo/${businessLogic.logoBlack}`} alt="" height={80}/>
                                    </Link>
                                 </div>
                              </div> */}


                              <div className="footer__widget-content">
                                 <div className="footer__info">
                                    <ul>
                                       <li>
                                          <div className="icon">
                                             <i > <BiMap /> </i>
                                          </div>
                                          <div className="text">
                                             <span>{businessLogic.address}</span>
                                          </div>
                                       </li>
                                       <li>
                                          <div className="icon theme-color ">
                                             <i > <FaEnvelope/> </i>
                                          </div>
                                          <div className="text theme-color ">
                                             <span><a href={`mailto:${businessLogic.emailBase}`}>{businessLogic.emailBase}</a></span>
                                          </div>
                                       </li>
                                       <li>
                                          <div className="icon theme-color">
                                             {/* <i ><FaPhoneAlt/> </i> */}
                                          </div>
                                          <div className="text theme-color">
                                             <span><a href={`${businessLogic.phoneNumber}`}>{businessLogic.phoneNumber}</a></span>
                                          </div>
                                       </li>
                                       {/* <Link to="/contact" className="z-btn z-btn-transparent mt-15" style={{ backgroundColor: '#25D366' }} >WhatsApp</Link> */}



                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>


                     </div>
                  </div>
               </div>






               <div className="footer__copyright">
                  <div className="container">
                     <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-6 col-md-6">
                           <div className="footer__copyright-text">
                              <p>{businessLogic.copyright}</p>
                           </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                           <div className="footer__social theme-social f-right">
                              <ul>

                              <li>
                                 <a href={`${businessLogic.linkedin}`} target="blank">
                                       <i ><FaLinkedin/> </i>
                                       <i ><FaLinkedin/> </i>
                                    </a>
                                 </li>

                                 <li>
                                 <a href={`${businessLogic.instagram}`} target="blank">
                                       <i ><FaInstagram/> </i>
                                       <i ><FaInstagram/> </i>
                                    </a>
                                 </li>

                                 <li>
                                 <a href={`${businessLogic.facebook}`} target="blank">
                                       <i ><FaFacebookF/></i>
                                       <i ><FaFacebookF/></i>
                                    </a>
                                 </li>

                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </footer>
      </>
   );
};

export default Footer;