import React, {useEffect } from 'react';
import ReactGA from "react-ga4";  
import { BrowserRouter, Routes, Route } from "react-router-dom";


import ScrollTop from "./components/ScrollTop";
import About from "./pages/About/About";
import BlogDetails from "./pages/BlogDetails/BlogDetails";
import Blog from "./pages/Blogs/Blogs";
import Resources from "./pages/Resources/Resources";
import Contact from "./pages/Contact/Contact";
// import HeaderStyleFive from "./pages/HeaderStyleFive/HeaderStyleFive";
// import HeaderStyleFour from "./pages/HeaderStyleFour/HeaderStyleFour";
// import HeaderStyleSeven from "./pages/HeaderStyleSeven/HeaderStyleSeven";
// import HeaderStyleSix from "./pages/HeaderStyleSix/HeaderStyleSix";
import Home from './pages/Home/Home';
import HomeThree from "./pages/HomeThree/HomeThree";
// import HomeTwo from "./pages/HomeTwo/HomeTwo";
import Portfolio from "./pages/Portfolio/Portfolio";
import PortfolioDetails from "./pages/PortfolioDetails/PortfolioDetails";
import Services from "./pages/Services/Services";
// import ServicesDetails from "./pages/ServicesDetails/ServicesDetails";
// import Team from "./pages/Team/Team";
// import TeamDetails from "./pages/TeamDetails/TeamDetails";
import CorePrelaunch from "./pages/CorePrelaunch/CorePrelaunch";
import Pricing from './pages/Pricing/Pricing';


// import ReactGA from 'react-ga';

import NewsletterPolicies from './pages/Policies/NewsletterPolicies';
import EBookPolicies from './pages/Policies/EBookPolicies';
import CommentPolicies from './pages/Policies/CommentsPolicies';
import ContactPolicies from './pages/Policies/ContactPolicies';
import CookiesPolicies from './pages/Policies/CookiesPolicies';

import LeadConversion from './pages/Integrations/LeadConversion';
import Error404 from './pages/error404';

const TRACKING_ID = "G-PX2NT49QKM"; // OUR_TRACKING_ID

// import Lottie from 'react-lottie';
// import animationData from '../public/assets/lottie/error-404.json'




// <!-- Google tag (gtag.js) -->
// <script async src="https://www.googletagmanager.com/gtag/js?id=G-PX2NT49QKM"></script>
// <script>
//   window.dataLayer = window.dataLayer || [];
//   function gtag(){dataLayer.push(arguments);}
//   gtag('js', new Date());

//   gtag('config', 'G-PX2NT49QKM');
// </script>


// <!-- Google tag (gtag.js) -->
{/* <script async src="https://www.googletagmanager.com/gtag/js?id=G-PX2NT49QKM"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-PX2NT49QKM');
</script> */}



  

ReactGA.initialize(TRACKING_ID);


// ReactGA.pageview(window.location.pathname + window.location.search);




function App() {

// https://medium.com/@perumalveni06/how-to-track-your-react-app-using-google-analytics-a57f3594447e
// medium tutorial outside the app function
// ReactGA.pageview(window.location.pathname + window.location.search);
// ReactGA.pageview(window.location.pathname);

useEffect(() => {
  const handlePageChange = () => {
    // ReactGA.pageview(window.location.pathname);
    // ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Custom Title" });
    ReactGA.send({ page: window.location.pathname });

  };

  window.addEventListener('popstate', handlePageChange);
  handlePageChange();

  return () => {
    window.removeEventListener('popstate', handlePageChange);
  };
}, []);


  // "start": "react-scripts start",
  // "build": "react-scripts build",
  

  
  return (
    <>
      <BrowserRouter>
        <ScrollTop/>
        <Routes>

            {/* HOME SECTION */}
            <Route path="/" element={<Home/>} />
            <Route path="/home" element={<Home/>} />
            
            {/* INTERESTING COMPONENTS FOR MAIN PAGE IN PAGE TWO */}
            {/* INTERESTING COMPONENTS FOR MAIN PAGE IN PAGE TWO */}
            {/* INTERESTING COMPONENTS FOR MAIN PAGE IN PAGE TWO */}
            
            {/* <Route path="/homeTwo" element={<HomeTwo/>} /> */}
            {/* INTERESTING POWER WORDS IN THESE TEMPLATES || LOOK UP MORE LATER  ********************* */}
            {/* <Route path="/homeThree" element={<HomeThree/>} />  */}



            <Route path="/newsletter-policies" element={<NewsletterPolicies/>} />
            <Route path="/ebook-policies" element={<EBookPolicies/>} />
            <Route path="/comments-policies" element={<CommentPolicies/>} />
            <Route path="/contact-policies" element={<ContactPolicies/>} />
            <Route path="/cookies-policies" element={<CookiesPolicies/>} />




            {/* <Route path="/time-maximization" element={<Services/>} />
            <Route path="/operational-efficiency" element={<Services/>} />
            <Route path="/customer-retention" element={<Services/>} />
            <Route path="/lead-conversion" element={<LeadConversion/>} /> */}




            {/* <Route path="/headerStyleFour" element={<HeaderStyleFour/>} />
            <Route path="/headerStyleFive" element={<HeaderStyleFive/>} />
            <Route path="/headerStyleSix" element={<HeaderStyleSix/>} />
            <Route path="/headerStyleSeven" element={<HeaderStyleSeven/>} /> */}





            {/* ABOUT SECTION */}
            <Route path="/about" element={<About/>} />




            {/* SERVICES SECTION */}
            <Route path="/services" element={<Services/>} />
            {/* <Route path="/servicesDetails" element={<ServicesDetails/>} /> */} {/* FUTURE USE FOR APP DETAIL */}



            {/* CORE PRELAUNCH SECTION */}
            <Route path="/core" element={<CorePrelaunch/>} /> {/* FUTURE USE FOR APP DETAIL */}


            {/* PORTFOLIO SECTION */}
            <Route path="/portfolio" element={<Portfolio/>} />
            <Route path="/portfolioDetails" element={<PortfolioDetails/>} /> {/* FUTURE USE FOR APP DETAIL */}

            {/* <Route path="/team" element={<Team/>} /> */} {/* FUTURE USE FOR APP DETAIL */}
            {/* <Route path="/teamDetails" element={<TeamDetails/>} /> */} {/* FUTURE USE FOR APP DETAIL */}


            {/* <Route path="/resources" element={<Resources/>} /> */}


                                           
                       


            {/* BLOG SECTION */}
            <Route path="/blog" element={<Blog/>} />
            {/* <Route path="/blogDetails" element={<BlogDetails/>} /> */}
            <Route path="/blog-details/:id" element={<BlogDetails/>} />


            {/* ERROR PAGES */}
            <Route path="/blog-details/error-404" element={<Error404/>} />
            <Route path="/error-404" element={<Error404/>} />
            
            {/* CONTACT */}
            <Route path="/contact" element={<Contact/>} />

            {/* FOOTER */}
            <Route path="/pricing" element={<Pricing/>} />

        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
