import React, { useContext, useEffect, useState } from 'react';
import HomeTwoSingleBlog from '../../../components/HomeTwoSingleBlog/HomeTwoSingleBlog';
import { CgArrowLongRight } from 'react-icons/cg';
import { Link } from 'react-router-dom';
import { constants } from '../../../lib/constants';
import { AppContext } from '../../../context/AllContext';
import LoaderSpiner from '../../../components/LoadersSpiner/loader';





const HomeTwoBlogs = () => {
   let getArticles = constants.getLastArticles;
   const { language } = useContext(AppContext);
 
 
   const [articles, setArticle] = useState([]);
 
   useEffect(() => {
     fetch(getArticles+'?language='+language)
       .then((res) => res.json())
       .then((data) => setArticle(data))
       .catch((e) => console.log(e));
 
   }, []);
 
   const filteredArticles = articles.filter((article) => article.quote !== true).slice(0, 3);


   const dataArray = filteredArticles.map((article) => {
      return (
         <HomeTwoSingleBlog date={article.createdAt.split("T")[0]} name={article.author} desc={article.title} link={article.link}/>

      //   <BlogSingleItem
      //      key={article.UUID}
      //     image="2"
      //     user_image="1"
      //     name={article.author}
      //     title={article.title}
      //     article={article}
      //     date={article.createdAt.split("T")[0]}
      //   />

      );
    });



   return (
      <>
         <section className="blog__area grey-bg-15 pt-115 pb-135">
            <div className="container">
               <div className="row">
                  <div className="col-xl-5">
                     <div className="section__title section__title-3 mb-65">
                        <span>Últimas noticias</span>
                        <h2>Mantente al día con nuestras últimas noticias del sector.</h2>
                     </div>
                  </div>
               </div>
               <div className="row">



               {articles.length > 0 ? (
                  // <div>{dataArray}</div>
                  <div style={{
                     display: "flex",
                     gap: "20px",
                     // overflowX: "auto",
                     padding: "10px"
                  }} >{dataArray}</div>

                  // <ContainerArticles data={currentPosts} />
                ) : (
                  <LoaderSpiner />
                )}


                  {/* <HomeTwoSingleBlog date="24" name="Elon Gated" desc="The Start-Up Ultimate Guide to Make Your WordPress Journal." />
                  <HomeTwoSingleBlog date="12" name="Lance Bogrol" desc="Businesses Are Thriving, Societies Are Not. Time for Urgent Change" />
                  <HomeTwoSingleBlog date="10" name="Shahnewaz Sakil" desc="For Banks, Modernizing Technology Has Become a Perpetual Challenge" /> */}

               </div>
               <div className="row">
                  <div className="col-xl-12">
                     <div className="blog__more mt-60">
                        <Link to="/blog" className="z-btn z-btn-border">Ver más noticias
                           <i > <CgArrowLongRight /> </i>
                        </Link>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};




 export default HomeTwoBlogs;


 
 